<template>
    <template v-if="visible">
        <Dialog v-model:visible="visible" :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :closable="false" :style="{ width: '30vw' }" header="Ειδοποιήσεις" modal>
            <p>
                θέλετε να ενημερώνεστε για όλες τις τελευταίες ειδήσεις;
            </p>
            <template #footer>
                <Button label="'Οχι" text @click="closeModal()"/>
                <Button id="subscribe-button" label="Ναι" text @click="accept() || closeModal()"/>
            </template>
        </Dialog>
    </template>
</template>

<script>

export default {
    data() {
        return {
            visible : false
        }
    },
    mounted() {
        const that = this;
        this.isPushEnabled()
            .then(function (enabled) {
                that.visible = !enabled && that.isPushNotificationSupported() && that.timePassed();
            })
            .catch(function (error) {
                console.error('An error occurred while checking push notification status:', error);
            });

    },
    methods : {
        timePassed() {
            const secondsElapsed = (parseInt((new Date().getTime() / 1000).toString()) - parseInt(localStorage.getItem('push_last_prompt') ?? '0'));
            return secondsElapsed > 86400;
        },
        isPushNotificationSupported() {
            return 'PushManager' in window;
        },
        accept() {
            new Promise(function (resolve, reject) {
                const permissionResult = Notification.requestPermission(function (result) {
                    resolve(result);
                });

                if (permissionResult) {
                    permissionResult.then(resolve, reject);
                }
            }).then((permissionResult) => {
                if (permissionResult !== 'granted') {
                    throw new Error('We weren\'t granted permission.');
                }
                this.subscribeUser();
            });
        },
        closeModal() {
            this.visible  = false;
            const seconds = parseInt((new Date().getTime() / 1000).toString());
            localStorage.setItem('push_last_prompt', seconds.toString());
        },
        isPushEnabled() {
            return new Promise(function (resolve, reject) {
                // Check if the user has granted permission for notifications
                if (Notification.permission !== 'granted') {
                    resolve(false);
                } else {
                    // Check if there is an existing subscription
                    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                        serviceWorkerRegistration.pushManager.getSubscription()
                            .then(function (subscription) {
                                resolve(subscription !== null);
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    });
                }
            });
        },
        subscribeUser() {
            window.swReady
                .then((registration) => {
                    const subscribeOptions = {
                        userVisibleOnly      : true,
                        applicationServerKey : this.urlBase64ToUint8Array(
                            'BM5HAtLtZel66GPqYbD1e1vuwPIs978eesbuqQ8ZOWYaz3qTACLEVxkOBStNyRHv_bR1eKeodQisdbUhu7vnxhY'
                        )
                    };
                    return registration.pushManager.subscribe(subscribeOptions);
                })
                .then((pushSubscription) => {
                    this.storePushSubscription(pushSubscription);
                });
        },
        storePushSubscription(pushSubscription) {
            const token = document.querySelector('meta[name=csrf-token]').getAttribute('content');

            fetch('/push', {
                method  : 'POST',
                body    : JSON.stringify(pushSubscription),
                headers : {
                    'Accept'       : 'application/json',
                    'Content-Type' : 'application/json',
                    'X-CSRF-Token' : token
                }
            }).then((res) => {
                return res.json();
            }).catch((err) => {
                console.log(err)
            });
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64  = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            const rawData     = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }
    }
}
</script>
