<template>
    <div v-show="show" class="pb-4">
        <div id="ww_37d4f318036eb" a='{"t":"responsive","lang":"en","sl_lpl":1,"ids":[],"font":"Arial","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722"}' loc='auto'
             v='1.3'>
            <a id="ww_37d4f318036eb_u" href="https://weatherwidget.org/" target="_blank">Widget weather HTML</a></div>
    </div>

</template>

<script>
export default {
    data() {
      return {
          show: false,
      }
    },
    mounted() {
        this.$nextTick(() => {
            this.loadWeatherWidgetScript();
        });
    },
    methods : {
        loadWeatherWidgetScript() {
            const script = document.createElement('script');
            script.async = true;
            script.src   = 'https://app2.weatherwidget.org/js/?id=ww_37d4f318036eb';
            document.body.appendChild(script);
            script.onload = () => {
                this.show = true;
            };
        }
    }

}
</script>
