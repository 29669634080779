<template>
    <div class="pt-3">
        <Link :href="route('newsportal.pharmacy')">
            <img :src="FarmakiaBanner" :srcset="FarmakiaBannerWebp + ', ' + FarmakiaBanner" alt="efimerevonta farmakia kipros" class="rounded-corners" width="340">
        </Link>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import FarmakiaBanner from '/resources/images/cyprusnews/farmakia-banner.jpg'
import FarmakiaBannerWebp from '/resources/images/cyprusnews/farmakia-banner.webp'

export default {
    components : {
        Link,
    },
    data() {
        return {
            FarmakiaBanner,
            FarmakiaBannerWebp,
        }
    },
}
</script>

<style scoped>
.rounded-corners {
    border-radius : 8px;
}
</style>
