<template>
    <HomeLayout :articles="articles" :weather="weather"/>
</template>

<script>
import { defineComponent } from "vue";
import { Head, Link } from "@inertiajs/vue3";
import HomeLayout from "@/Layouts/NewsPortal/HomeLayout.vue";

export default defineComponent({
    components: {
        Head,
        Link,
        HomeLayout,
    },
    props: {
        articles: Object,
        weather: Object,
    },
});
</script>
