<template>
    <div class="py-6 px-3 md:px-6 shadow-2 text-center" style="background: linear-gradient(180deg, #6372c3 0%, #3f51b5 100%); border-radius: 1rem">
        <div class="text-white font-bold text-2xl mb-3">{{__('Subscribe to our Newsletter')}}</div>
        <span class="block text-indigo-100 font-medium text-xl">{{__('Get all the latest news in your email!')}}</span>
        <div class="mt-5 mb-3 relative sm:w-18rem mx-auto">
            <FormField v-model="email" class="appearance-none bg-indigo-600 border-1 border-indigo-400 py-3 pl-3 w-full p-component text-indigo-100 outline-none"
                       name="email" placeholder="john@gmail.com" style="border-radius: 35px; padding-right:6rem"/>
            <Button class="absolute" label="Join" style="border-radius: 35px; top: .25rem; right: .25rem" type="button" @click="subscribe"></Button>
        </div>
        <span class="text-indigo-200">{{__('We respect your privacy.')}}</span>
    </div>
</template>

<script>
import FormField from '@/Components/Primitives/FormField.vue';

export default {
    components : {FormField},
    data() {
        return {
            email : '',
        }
    },
    methods : {
        subscribe() {
            this.$inertia.post(route('newsletter.subscribe'), {email : this.email}, {
                preserveScroll : true,
                onSuccess      : () => {
                    this.$toast.add({severity : 'success', detail : 'You have successfully subscribed to our newsletter. Please check your email to confirm your subscription.', life : 5000});
                    this.email = '';
                },
            });

        }
    }
}
</script>